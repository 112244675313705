import React from 'react';
import MailChimpForm from '../components/MailChimpForm';
import Layout from '../components/layout/Layout';
import SEO from '../components/seo';

const SignUp = () => {
  return (
    <Layout>
      <SEO title="Sign Up " />
      <div className="container mx-auto w-full ">
        <h1 className="text-center text-2xl py-8 leading-8">
          Glad You&apos;re here! <br />
          Let us know a little about your business.
        </h1>
        <MailChimpForm />
      </div>
    </Layout>
  );
};

export default SignUp;
